<script>
// import vue2Dropzone from "vue2-dropzone";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import DatePicker from "vue2-datepicker";
import Swal from "sweetalert2";
import useNetw from "@useNetw";

import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";

/**
 * Add-product component
 */
export default {
  page: {
    title: "แก้ไขข้อมูลรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    // vueDropzone: vue2Dropzone,
    Multiselect,
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      vin: "",
      chassisNumber: "",
      engineNumber: "",
      platePrefixNumber: "",
      plateNumber: "",
      licensePlate: "",
      gearSystemId: "",
      modelYear: "",
      seat: "",
      warrantyBookNo: "",
      keyNo: "",
      cc: "",
      deliveryDate: "",
      registrationDate: "",
      coverageEndDate: "",
      coverageEndMileNumber: "",
      lastServiceDate: "",
      previousServiceDate: "",
      lastInvoiceNumber: "",
      previousInvoiceNumber: "",
      previousMileNumber: "",
      mileNumber: "",
      averageMileNumber: "",
      predictMileNumber: "",
      branchId: [],
      plateProvinceId: "",
      ownerId: "",
      driverId: "",
      typeId: "",
      brandId: "",
      modelId: "",
      interiorColorId: "",
      exteriorColorId: "",
      gearSystemNameTh: "",
      provinceNameTh: "",
      vehicleTypeNameTh: "",
      vehicleModelEn: "",
      vehicleBrandEn: "",

      userBranch: "",

      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      masterBrand: [],
      masterModel: [],
      masterType: [],
      masterGear: [],
      provinces: [],
      rowsUser: [],
      branchMaster: [
        {
          id: 1,
          nameTh: "สุริยะไอที สำนักงานใหญ่",
        },
      ],
      title: "แก้ไขข้อมูลรถ",
      submitform: false,
      submit: false,
      typesubmit: false,
      value: null,
      rows: [],
      dataTest: [],
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "ข้อมูลรถ",
          active: false,
          href: "/vehiclelist",
        },
        {
          text: "แก้ไขข้อมูลรถ",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      overlayFlag: false,
    };
  },
  validations: {
    vin: {
      required,
    },
    driverId: {
      required,
    },
    platePrefixNumber: {
      required,
    },
    plateProvinceId: {
      required,
    },
    plateNumber: {
      required,
    },
    branchId: {
      required,
    },
  },
  computed: {
    fullLicense: function() {
      return `${this.platePrefixNumber != null ? this.platePrefixNumber : ""} ${
        this.plateNumber != null ? this.plateNumber : ""
      } ${
        this.plateProvinceId != null ? this.plateProvinceId.nameTh : ""
      }`;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.userBranch = arrayBranch;
    // const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
  },
  created() {
    // this.getSelecttest();
    this.getSelectMasterBrand();
    this.getSelectMasterType();
    this.getSelectMasterGear();
    this.getDetailVehicle();
    this.getSelectMasterProvinces();
  },
  methods: {
    submitUpdateVehicles: function() {
      useNetw
        .put("/api/vehicle/update", {
          vehicleId: this.vehicleId,
          chassisNumber: this.chassisNumber != null ? this.chassisNumber : "",
          engineNumber: this.engineNumber != null ? this.engineNumber : "",
          platePrefixNumber:
            this.platePrefixNumber != null ? this.platePrefixNumber : "",
          plateNumber: this.plateNumber != null ? this.plateNumber : "",
          licensePlate: this.fullLicense != null ? this.fullLicense : "",
          gearSystemId:
            this.gearSystemId.gearSystemId != null
              ? this.gearSystemId.gearSystemId
              : "",
          modelYear: this.modelYear != null ? this.modelYear : "",
          seat: this.seat != null ? this.seat : "",
          warrantyBookNo:
            this.warrantyBookNo != null ? this.warrantyBookNo : "",
          keyNo: this.keyNo != null ? this.keyNo : "",
          cc: this.cc != null ? this.cc : "",
          deliveryDate: this.deliveryDate != null ? this.deliveryDate : "",
          registrationDate:
            this.registrationDate != null ? this.registrationDate : "",
          coverageEndDate:
            this.coverageEndDate != null ? this.coverageEndDate : "",
          coverageEndMileNumber:
            this.coverageEndMileNumber != null
              ? this.coverageEndMileNumber
              : "",
          lastServiceDate:
            this.lastServiceDate != null ? this.lastServiceDate : "",
          previousServiceDate:
            this.previousServiceDate != null ? this.previousServiceDate : "",
          lastInvoiceNumber:
            this.lastInvoiceNumber != null ? this.lastInvoiceNumber : "",
          previousInvoiceNumber:
            this.previousInvoiceNumber != null
              ? this.previousInvoiceNumber
              : "",
          previousMileNumber:
            this.previousMileNumber != null ? this.previousMileNumber : "",
          mileNumber: this.mileNumber != null ? this.mileNumber : "",
          averageMileNumber:
            this.averageMileNumber != null ? this.averageMileNumber : "",
          predictMileNumber:
            this.predictMileNumber != null ? this.predictMileNumber : "",
          branchId: this.branchId,
          plateProvinceId:
            this.plateProvinceId.provinceId != null
              ? this.plateProvinceId.provinceId
              : "",
          ownerId: this.ownerId != null ? this.ownerId : "",
          driverId: this.driverId.cusId != null ? this.driverId.cusId : "",
          typeId: this.typeId.typeId != null ? this.typeId.typeId : "",
          brandId: this.brandId.brandId != null ? this.brandId.brandId : "",
          modelId: this.modelId.modelId != null ? this.modelId.modelId : "",
          interiorColorId:
            this.interiorColorId != null ? this.interiorColorId : "",
          exteriorColorId:
            this.exteriorColorId != null ? this.exteriorColorId : "",
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.getSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.$router.push({ name: "vehiclelist" });
        });
    },
    getSelectMasterBrand: function() {
      useNetw
        .get("/api/master/vehicle/brands", {})
        .then((response) => {
          this.masterBrand = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterModel: function(isReset) {
      useNetw
        .get("/api/master/vehicle/models", {
          params: {
            branchId: 1,
            brandId: this.brandId.brandId,
          },
        })
        .then((response) => {
          this.masterModel = response.data.data;
          if (isReset) {
            this.modelId = null;
          }
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          if (isReset) {
            this.brandId = null;
            this.modelId = null;
          }
        })
        .then(() => {});
    },
    getSelectMasterType: function() {
      useNetw
        .get("/api/master/vehicle/types", {})
        .then((response) => {
          this.masterType = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getSelectMasterGear: function() {
      useNetw
        .get("/api/master/vehicle/gear-systems", {})
        .then((response) => {
          this.masterGear = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },

    getDetailVehicle: function() {
      this.overlayFlag = true;
      useNetw
        .get("/api/vehicle/show", {
          params: {
            vehicleId: this.$route.params.vehicleId,
          },
        })
        .then((response) => {
          this.vin = response.data.data.vin;
          this.vehicleId = response.data.data.vehicleId;
          this.chassisNumber = response.data.data.chassisNumber;
          this.engineNumber = response.data.data.engineNumber;
          this.platePrefixNumber = response.data.data.platePrefixNumber;
          this.plateNumber = response.data.data.plateNumber;
          this.licensePlate = response.data.data.licensePlate;
          this.gearSystemId = {
            gearSystemId: response.data.data.gearSystemId,
            nameTh: response.data.data.gearSystemNameTh,
          };
          this.modelYear = response.data.data.modelYear;
          this.seat = response.data.data.seat;
          this.warrantyBookNo = response.data.data.warrantyBookNo;
          this.keyNo = response.data.data.keyNo;
          this.cc = response.data.data.cc;
          this.deliveryDate = response.data.data.deliveryDate;
          this.registrationDate = response.data.data.registrationDate;
          this.coverageEndDate = response.data.data.coverageEndDate;
          this.coverageEndMileNumber = response.data.data.coverageEndMileNumber;
          this.lastServiceDate = response.data.data.lastServiceDate;
          this.previousServiceDate = response.data.data.previousServiceDate;
          this.lastInvoiceNumber = response.data.data.lastInvoiceNumber;
          this.previousInvoiceNumber = response.data.data.previousInvoiceNumber;
          this.previousMileNumber = response.data.data.previousMileNumber;
          this.mileNumber = response.data.data.mileNumber;
          this.averageMileNumber = response.data.data.averageMileNumber;
          this.predictMileNumber = response.data.data.predictMileNumber;
          this.plateProvinceId = {
            plateProvinceId: response.data.data.plateProvinceId,
            nameTh: response.data.data.provinceNameTh != null ? response.data.data.provinceNameTh : "",
          };
          this.ownerId = response.data.data.ownerId;
          this.driverId = {
            driverId: response.data.data.driverId,
            nameTh:
              response.data.data.driverNameTh != null
                ? response.data.data.driverNameTh
                : "",
          };
          this.typeId = {
            typeId: response.data.data.typeId,
            nameTh: response.data.data.vehicleTypeNameTh,
          };
          this.brandId = {
            brandId: response.data.data.brandId,
            nameEn: response.data.data.vehicleBrandEn,
          };
          this.modelId = {
            modelId: response.data.data.modelId,
            nameEn: response.data.data.vehicleModelEn,
          };
          this.interiorColorId = response.data.data.interiorColorId;
          this.exteriorColorId = response.data.data.exteriorColorId;
          this.branchId = response.data.data.branchId;
          this.gearSystemNameTh = response.data.data.gearSystemNameTh;
          this.vehicleTypeNameTh = response.data.data.vehicleTypeNameTh;
          this.vehicleModelEn = response.data.data.vehicleModelEn;
          this.vehicleBrandEn = response.data.data.vehicleBrandEn;
          // this.tttt = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitUpdateVehicles();
      }
    },

    getSelectMasterProvinces: function() {
      useNetw
        .get("/api/master/address/provinces", {})
        .then((response) => {
          this.provinces = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {});
    },
    getDataCus: function(driver) {
      // this.overlayFlag = true;
      this.loading = true; //skeleton true
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
            nameTh: driver,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },

    getRemoveSelected: function() {
      this.isLoading = true;
      useNetw
        .get("api/customer", {
          params: {
            page: 1,
            perPage: 100,
            branchId: this.userBranch,
          },
        })
        .then((response) => {
          this.rowsUser = response.data.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },

    customLabel({ nameTh }) {
      return `${nameTh}`;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <b-overlay
            :show="overlayFlag"
            :spinner-variant="this.$overlayVariant"
            :spinner-type="this.$overlayType"
            :rounded="this.$overlayRounded"
          >
            <div class="card">
              <a
                href="javascript: void(0);"
                class="text-dark"
                data-toggle="collapse"
                aria-expanded="true"
                aria-controls="addproduct-billinginfo-collapse"
                v-b-toggle.accordion-1
              >
                <div class="p-4">
                  <div class="media align-items-center">
                    <div class="me-3">
                      <div class="avatar-xs">
                        <div
                          class="avatar-title rounded-circle bg-soft-primary text-primary"
                        >
                          01
                        </div>
                      </div>
                    </div>
                    <div class="media-body overflow-hidden">
                      <h5 class="font-size-16 mb-1">ข้อมูลรถ</h5>
                      <!-- <p class="text-muted text-truncate mb-0">
                      Fill all information below
                    </p> -->
                    </div>
                    <i
                      class="mdi mdi-chevron-up accor-down-icon font-size-24"
                    ></i>
                  </div>
                </div>
              </a>

              <b-collapse
                data-parent="#addproduct-accordion"
                id="accordion-1"
                visible
                accordion="my-accordion"
              >
                <div class="p-4 border-top">
                  <div class="row align-items-end">
                    <div class="col-8 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code
                        ><label for="validationTooltip03">เลขตัวถัง:</label>
                        <input
                          id="validationTooltip03"
                          v-model="vin"
                          type="text"
                          class="form-control"
                          :maxlength="20"
                          :class="{
                            'is-invalid': submitform && $v.vin.$error,
                          }"
                          disabled
                        />
                        <div
                          v-if="submitform && $v.vin.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.vin.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip03">หมายเลขตัวถัง:</label>
                        <input
                          id="validationTooltip05"
                          v-model="chassisNumber"
                          type="text"
                          class="form-control"
                          :maxlength="10"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip04">เลขเครื่อง:</label>
                        <input
                          v-model="engineNumber"
                          type="text"
                          class="form-control"
                          :maxlength="20"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                      <label for="validationTooltip01">เจ้าของรถ:</label>
                      <multiselect
                        v-model="ownerId"
                        label="nameTh"
                        type="search"
                        :options="rowsUser"
                        :show-labels="false"
                        :custom-label="customLabel"
                        open-direction="bottom"
                        @search-change="getDataCus"
                        @remove="getRemoveSelected"
                        placeholder=""
                        class="helo"
                        default
                      >
                        <span slot="noResult">ไม่พบข้อมูล</span>
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <span class="option__title"
                              >{{ props.option.nameTh }}&nbsp;</span
                            ><span class="option__small">{{
                              props.option.familyNameTh
                            }}</span>
                          </div>
                        </template>
                      </multiselect>
                    </div> 
                    </div> -->
                    <div class="col-7 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">ผู้ขับขี่:</label>
                        <multiselect
                          v-model="driverId"
                          label="nameTh"
                          type="search"
                          :options="rowsUser"
                          :show-labels="false"
                          :custom-label="customLabel"
                          open-direction="bottom"
                          @search-change="getDataCus($event)"
                          @remove="getRemoveSelected"
                          placeholder=""
                          :class="{
                            'is-invalid': submitform && $v.driverId.$error,
                          }"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                          <template slot="option" slot-scope="props">
                            <div class="option__desc">
                              <span class="option__title"
                                >{{ props.option.nameTh }}&nbsp;</span
                              ><span class="option__small">{{
                                props.option.familyNameTh
                              }}</span>
                            </div>
                          </template>
                        </multiselect>
                        <div
                          v-if="submitform && $v.driverId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.driverId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3 col-sm-4 col-md-1">
                      <div class="mb-3 position-relative">
                        <code> * </code
                        ><label for="validationTooltip04">อักษรหน้า:</label>
                        <input
                          v-model="platePrefixNumber"
                          type="text"
                          class="form-control"
                          :maxlength="4"
                          :class="{
                            'is-invalid':
                              submitform && $v.platePrefixNumber.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.platePrefixNumber.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.platePrefixNumber.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 col-sm-3 col-md-2">
                      <div class="mb-3 position-relative">
                        <code> * </code
                        ><label for="validationTooltip02">เลขป้าย:</label>
                        <input
                          id="validationTooltip02"
                          v-model="plateNumber"
                          type="text"
                          class="form-control"
                          value="Otto"
                          :maxlength="4"
                          :class="{
                            'is-invalid': submitform && $v.plateNumber.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.plateNumber.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.plateNumber.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <code> * </code
                        ><label for="validationTooltip01">จังหวัด:</label>
                        <multiselect
                          v-model="plateProvinceId"
                          label="nameTh"
                          :options="provinces"
                          :show-labels="false"
                          :class="{
                            'is-invalid':
                              submitform && $v.plateProvinceId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.plateProvinceId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.plateProvinceId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-5 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsername1"
                          >ป้ายทะเบียน</label
                        >
                        <div class="input-group">
                          <input
                            v-model="fullLicense"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend1"
                            disabled
                          />
                        </div>
                        {{ fullLicense }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-5 col-sm-4 col-md-4">
                      <div class="mb-3 position-relative">
                        <label>ยี่ห้อ:</label>
                        <div class="input-group">
                          <multiselect
                            v-model="brandId"
                            label="nameEn"
                            :options="masterBrand"
                            :show-labels="false"
                            class="helo"
                            @input="getSelectMasterModel"
                          >
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-7 col-sm-5 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">รุ่น:</label>
                        <multiselect
                          v-model="modelId"
                          label="nameEn"
                          :options="masterModel"
                          :show-labels="false"
                          class="helo"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsername1">ปีรุ่น:</label>
                        <div class="input-group">
                          <input
                            v-model="modelYear"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend1"
                            :maxlength="4"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-2 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">CC:</label>
                        <input
                          id="validationTooltip01"
                          v-model="cc"
                          type="text"
                          class="form-control"
                          value="Mark"
                        />
                      </div>
                    </div>
                    <div class="col-7 col-sm-5 col-md-4">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsername1">เกียร์:</label>
                        <div class="input-group">
                          <multiselect
                            v-model="gearSystemId"
                            label="nameTh"
                            :options="masterGear"
                            :show-labels="false"
                            class="helo"
                            default
                          >
                          </multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-5 col-sm-5 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">ประเภทรถ:</label>
                        <multiselect
                          v-model="typeId"
                          label="nameTh"
                          :options="masterType"
                          :show-labels="false"
                          class="helo"
                        >
                        </multiselect>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">สีภายใน:</label>
                        <input
                          v-model="interiorColorId"
                          type="text"
                          class="form-control"
                          value="Mark"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <label>สีภายนอก:</label>
                        <div class="input-group">
                          <input
                            v-model="exteriorColorId"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend4"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsernamePrepend2"
                          >ที่นั่ง</label
                        >
                        <div class="input-group">
                          <input
                            id="validationTooltipUsername2"
                            v-model="seat"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend2"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip02">warrantyBookNo:</label>
                        <input
                          id="validationTooltip08"
                          v-model="warrantyBookNo"
                          type="text"
                          class="form-control"
                          value="Otto"
                          :maxlength="45"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltipUsernamePrepend3"
                          >keyNo:</label
                        >
                        <div class="input-group">
                          <input
                            id="validationTooltipUsername3"
                            v-model="keyNo"
                            type="text"
                            class="form-control"
                            :maxlength="45"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">วันที่จัดส่ง:</label>
                        <date-picker
                          v-model="deliveryDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >วันที่ลงทะเบียน:</label
                        >
                        <date-picker
                          v-model="registrationDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-3">
                      <div class="mb-3 position-relative">
                        <label>วันที่สิ้นสุดความคุ้มครอง:</label>
                        <div class="input-group">
                          <date-picker
                            v-model="coverageEndDate"
                            format="YYYY-MM-DD"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >ไมล์สิ้นสุดความคุ้มครอง</label
                        >
                        <input
                          v-model="coverageEndMileNumber"
                          type="text"
                          class="form-control"
                          value="Mark"
                          :maxlength="20"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >วันที่ให้บริการล่าสุด</label
                        >
                        <date-picker
                          v-model="lastServiceDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label>วันที่ให้บริการก่อนหน้า:</label>
                        <div class="input-group">
                          <date-picker
                            v-model="previousServiceDate"
                            format="YYYY-MM-DD"
                            value-type="format"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >เลขที่ใบแจ้งหนี้ล่าสุด:</label
                        >
                        <input
                          v-model="lastInvoiceNumber"
                          type="text"
                          class="form-control"
                          value="Mark"
                          :maxlength="20"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >หมายเลขใบแจ้งหนี้ก่อนหน้า:</label
                        >
                        <input
                          v-model="previousInvoiceNumber"
                          type="text"
                          class="form-control"
                          value="Mark"
                          :maxlength="20"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label>หมายเลขไมล์ก่อนหน้า:</label>
                        <div class="input-group">
                          <input
                            v-model="previousMileNumber"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend4"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">เลขไมล์:</label>
                        <input
                          v-model="mileNumber"
                          type="text"
                          class="form-control"
                          value="Mark"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-4 col-md-2">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01"
                          >จำนวนไมล์เฉลี่ย:</label
                        >
                        <input
                          v-model="averageMileNumber"
                          type="text"
                          class="form-control"
                          value="Mark"
                        />
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-2">
                      <div class="mb-3 position-relative">
                        <label>คาดการณ์ไมล์:</label>
                        <div class="input-group">
                          <input
                            v-model="predictMileNumber"
                            type="text"
                            class="form-control"
                            aria-describedby="validationTooltipUsernamePrepend4"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="validationTooltip01">สาขา:</label>
                        <multiselect
                          v-model="branchId"
                          label="nameTh"
                          :options="branchMaster"
                          :show-labels="false"
                          class="helo"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.branchId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.branchId.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="row mb-4">
                    <div class="col text-end ms-1">
                      <b-button
                        class="btn btn-danger"
                        :to="{ name: 'vehiclelist' }"
                      >
                        <i class="uil uil-times me-1"></i> Cancel
                      </b-button>
                      <button
                        href="#"
                        type="submit"
                        class="btn btn-success ms-1"
                        @click="tooltipForm"
                      >
                        <i class="uil uil-file-alt me-1"></i> Save
                      </button>
                    </div>
                    <!-- end col -->
                  </div>
                </div>
              </b-collapse>
            </div>
          </b-overlay>
        </div>
      </div>
    </div>
  </Layout>
</template>
